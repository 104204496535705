import {createGlobalStyle} from 'styled-components';

export const HPlusGlobalStyle = createGlobalStyle`
    html, body {
        height: 100%;
        min-height: 100vh;
    }

    #__next {
        height: 100%;
    }
`;
